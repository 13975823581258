import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  AppProvider,
  Banner,
  DisplayText,
  Layout,
  SkeletonPage,
  Spinner,
  Stack,
} from "@shopify/polaris"
import { Loading } from "@shopify/app-bridge-react"
import { appName } from "../../helpers/app"
import ReactDOMServer from "react-dom/server"
import GoogleMapReact from "google-map-react"
import { HeatMappableOrder } from "../../helpers/types"
import { colorGreenDark } from "@shopify/polaris-tokens"
import stc from "string-to-color"
import { CustomerInfo } from "./customer-info"
import enTranslations from "@shopify/polaris/locales/en.json"

export const OrderMap: React.FC<{
  mapsApiKey?: string
  orders?: HeatMappableOrder[]
  shop: string
  showMarkers?: boolean
  showHeatMap?: boolean
  changeHandler?: (change: GoogleMapReact.ChangeEventValue) => void
}> = ({
  mapsApiKey,
  orders = [],
  shop,
  changeHandler,
  showMarkers = true,
  showHeatMap = false,
}) => {
  const defaultProps = {
    center: {
      lat: 41.85,
      lng: -87.65,
    },
  }
  const [googleMapsKey] = useState(
    mapsApiKey || process.env.GOOGLE_MAPS_API_KEY
  )
  const [mapObjects, setMapObjects] = useState<{ maps: any; map: any }>()
  // const [filteredDataPoints, setFilteredDataPoints] = useState<HeatMappableOrder[]>()
  // const [dataPoints] = useState(orders)
  const [dataPoints, setDataPoints] = useState<HeatMappableOrder[]>()
  const [markers, setMarkers] = useState<any[]>([])
  const [zoom, setZoom] = useState(4)
  const [visibleInfoWindow, setVisibleInfoWindow] = useState(0)
  const prevVisibleInfoWindowRef = useRef<any>()
  const prevVisibleInfoWindow = prevVisibleInfoWindowRef.current

  const getInfoWindowString = (order: any, shop: string) => {
    return (
      <AppProvider i18n={enTranslations}>
        <CustomerInfo customer={order.customer} />
      </AppProvider>
    )
  }
  const createMarker = (
    maps: any,
    map: any,
    order: HeatMappableOrder,
    shop: string
  ) => {
    const infoWindow = new maps.InfoWindow({
      content: ReactDOMServer.renderToStaticMarkup(
        getInfoWindowString(order, shop)
      ),
    })

    const possibleTags = [
      ...(order.tags || []),
      ...(order.customer ? order.customer.tags || [] : []),
    ].filter(() => Boolean)
    // Which tag should the color reference come from
    // const markerRefTag = Array.isArray(order.tags) && order.tags.length > 0 ? order.tags : [colorGreenDark];
    const markerRefTag = (possibleTags.length > 0
      ? possibleTags
      : [undefined]
    ).shift()
    const color = markerRefTag ? stc(markerRefTag) : colorGreenDark
    // console.log({ markerRefTag, color })
    const svgMarker = {
      // path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      // anchor: new maps.Point(12, 20),

      // path: maps.SymbolPath.CIRCLE,
      // LocationMinor
      path:
        "M10 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-8C6.69 2 4 4.87 4 8.4c0 6 5.4 9.35 5.63 9.49.11.07.24.11.37.11s.26-.04.37-.11C10.6 17.75 16 14.4 16 8.4 16 4.87 13.31 2 10 2z",
      fillColor: color,
      // fillOpacity: 0.6,
      fillOpacity: 1,
      anchor: new maps.Point(10, 19),
      strokeWeight: 0,
      rotation: 0,
      scale: 1,
    }

    const marker = new maps.Marker({
      position: {
        lat: order.latitude,
        lng: order.longitude,
      },
      icon: svgMarker,
      map,
    })
    marker.addListener("click", () => {
      //   setVisibleMarker(marker)
      setVisibleInfoWindow(infoWindow)
      infoWindow.open(map, marker)
    })
    marker.customerId = order.customer ? order.customer.id : undefined
    return { marker, infoWindow }
  }
  useEffect(() => {
    // for (let i = 0; i < markers.length; i++) {
    // markers[i].setMap(null)
    //   }

    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null)
    }
    if (
      !dataPoints ||
      dataPoints.length <= 0 ||
      typeof mapObjects !== "object"
    ) {
      setMarkers([])
      return
    }
    const { map, maps } = mapObjects
    const displayedMarkers: any[] = []
    dataPoints.forEach((order, i) => {
      if (order && typeof order === "object") {
        const { customer, latitude, longitude } = order
        if (customer && typeof customer === "object" && latitude && longitude) {
          const { marker } = createMarker(maps, map, order, shop)
          displayedMarkers.push(marker)
        }
      }
    })
    setMarkers(displayedMarkers)
  }, [mapObjects, dataPoints])

  // useEffect(() => {
  //   setFilteredDataPoints(filteredPoints)
  // }, [filteredPoints])

  useEffect(() => {
    // console.log({orders});
    setDataPoints(orders)
  }, [orders])

  useEffect(() => {
    if (typeof mapObjects !== "object" || showMarkers === false) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null)
      }
      return
    }
    const { map } = mapObjects
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map)
    }
  }, [showMarkers, mapObjects, markers])

  useEffect(() => {
    if (
      prevVisibleInfoWindow &&
      typeof prevVisibleInfoWindow.close === "function"
    ) {
      prevVisibleInfoWindow.close()
    }
  }, [visibleInfoWindow])

  // Persist the info window ref so it can be closed
  useEffect(() => {
    prevVisibleInfoWindowRef.current = visibleInfoWindow
  })

  const onBoundsChange = useCallback((center: any, zoom: any, bounds: any, marginBounds: any) => {
    console.log({center, zoom, bounds, marginBounds});
    
  },[]);

  if (typeof googleMapsKey !== "string") {
    return (
      <Stack distribution="center">
        <Spinner />
      </Stack>
    )
  } else {
    return (
      <Stack distribution="fill" vertical>
        <div style={{ height: "60vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleMapsKey,
              libraries: ["visualization"],
            }}
            defaultCenter={defaultProps.center}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => setMapObjects({ map, maps })}
            onChange={(changes) => {
              // console.log({changes})
              const { zoom } = changes
              setZoom(zoom)
              if (changeHandler) {
                changeHandler(changes);
              }
            }}
            heatmap={
              showHeatMap
                ? {
                    positions: dataPoints?.map((point) => ({...point, lat: point.latitude, lng: point.longitude})) || [],
                    options: {
                      radius: 20,
                      // gradient,
                    },
                  }
                : { positions: [], options: {} }
            }
            options={{
              styles: [
                {
                  stylers: [
                    {
                      saturation: -100,
                    },
                    { gamma: 0.8 },
                  ],
                },
              ],
            }}
          ></GoogleMapReact>
        </div>
        {/* <div>
          {JSON.stringify({ orders: orders?.length })}
        </div> */}
      </Stack>
    )
  }
  return (
    <Banner title="Unknown error">
      <p>Unknown error loading map</p>
    </Banner>
  )
}
