import { Order } from "./types";

export interface ValueParams {
    collections?: {id: number, value: number | boolean}[]
    newThreshold?: number // Number of days after new to consider new
  }
export const calcOrderValue = (order: Order, params?: ValueParams): number => {
  return calcOrderValues(order, params).reduce((p, c) => p + c.value, 0);
};

export const calcOrderValues = (order: Order, params?: ValueParams): {id: string, value: number}[] => {

    const {subtotalPrice} = order;
    const {collections, newThreshold = 0} = params || {};
    const values: {id: string, value: number}[] = [];

    // If collections sort line items into collection values
    if (Array.isArray(collections)) {
      let counter:{[collection: string]: number} = collections.reduce((p,c) => ({...p,[c.id]: 0}), {})
      const valueHolder:{[collection: string]: number} = collections.reduce((p,c) => ({...p,[c.id]: c.value}), {})
      order.lineItems && order.lineItems.map((lineItem) => {
        collections.forEach((collection) => {
          if (lineItem.collections.includes(collection.id) ) {
            const lineItemTotal = lineItem.discountedTotal || 0;
            const value = valueHolder[collection.id] ? lineItemTotal * valueHolder[collection.id] : 1;
            // counter[collection.id] += value;
            counter[collection.id] += lineItemTotal;
          } else {

          }
        })
      })
      // Push counter totals to values array
      for (const [id, value = 0] of Object.entries(counter)) {
        values.push({id, value: parseFloat(`${value}`)})
      }
    }

    // is Sample and?? R&D Order

    // is Coco


    // Is Nutes


   return values; 
}

// const customerSinceDate = (customerData: CustomerData) => new Date(customerData.)