import React, { useState, useCallback, useContext } from "react"
import {
  TextContainer,
  Button,
  Tag,
  Stack,
  TextStyle, DataTable
} from "@shopify/polaris"
import { Order } from "../../helpers/types"
import { Context } from "@shopify/app-bridge-react"
import { Redirect } from "@shopify/app-bridge/actions"
import { formatMoney } from "../../helpers/formatting"
import { TagIcon } from "./tag-icon"

// if (!context) {
//     return <>No context</>;
// }
export const OrderInfo: React.FC<{
  order: Order
}> = ({ order }) => {
  const context = useContext(Context)
  const redirectToCustomer = useCallback(() => {
    if (!context) {
      return
    }
    // If a string extract the numbers
    // const id = `${order.id.match(/[0-9 , \.]+/g)}`
    const id = typeof order.id === 'number' ? order.id.toString() : order.id;
    const redirect = Redirect.create(context)
    redirect &&
      redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
        name: Redirect.ResourceType.Order,
        resource: { id },
      })
  }, [context])
  const clickedLineItem = useCallback((id) => {
    console.log(`Line item clicked`, id)
  }, [])
  return (
    <Stack vertical>
      <TextContainer>
        <TextStyle variation="strong">Order {order.name}</TextStyle> <br />
        <TextStyle variation="subdued">
          {/* {customer.ordersCount} order{customer.ordersCount > 1 ? "s" : ""} starting {customer.lifetimeDuration} ago */}
        </TextStyle>
      </TextContainer>
      { Array.isArray(order.tags) && (

      <Stack spacing="extraTight">
        {order.tags.map((tag) => (
            <Tag key={tag}>
            {
              ((
                <Stack spacing="extraTight">
                  <TagIcon tag={tag} /> <span>{tag}</span>
                </Stack>
              ) as unknown) as string
            }
          </Tag>
        ))}
      </Stack>
      )}
      <DataTable 
        headings={['Name', 'Quantity', 'Amount']}
        columnContentTypes={['text', 'numeric', 'text']}
        rows={order.lineItems.map((item) => {
          return [item.name, item.quantity, `$${formatMoney(item.price || 0)}`]
        })}
      />
      <Button onClick={redirectToCustomer}>View Order</Button>
    </Stack>
  )
}
