import firebaseLib from "firebase/app"
import { HeatMappableOrder } from "../types"

export const OrderMappableConverter = {
  toFirestore(point: Partial<HeatMappableOrder>) {
    throw new Error("Mappable points cannot be converted back to orders")
  },
  fromFirestore(
    snapshot: firebaseLib.firestore.QueryDocumentSnapshot,
    options: firebaseLib.firestore.SnapshotOptions
  ): HeatMappableOrder {
    const tsToDate = (d: any) => d && typeof d.toDate === 'function' ? d.toDate() : null;
    const data = snapshot.data(options)
      // if (
      //   data &&
      //   data.shippingAddress &&
      //   data.shippingAddress.latitude
      // ) {
    
        const { shippingGeo } = data;
        const { latitude, longitude } = shippingGeo || {latitude: null, longitude: null};
        return {
          shippingAddress: data.shippingAddress,
          //   shippingLine: data.shippingLine,
          subtotalPrice: typeof data.subtotalPrice === 'number' ? data.subtotalPrice : 0,
          name: data.name,
          shop: data.shop,
          customer: data.customer,
          latitude,
          longitude,
          id: data.id,
          tags: data.tags,
          createdAt: tsToDate(data.createdAt),
          updatedAt: tsToDate(data.updatedAt),
          lineItems: data.lineItems,
        };
    // }
  },
}
