import React, { useState, useCallback } from "react"
import {
  Button,
  ChoiceList,
  Heading,
  List,
  Scrollable,
  Sheet,
  Stack,
} from "@shopify/polaris"
import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

export const TagSheet: React.FC<{
  sheetActive?: boolean
  heading?: string
  closeAction: () => void
  tagsSelected?: (tags: string[]) => void
  tags?: { value: string; label: React.ReactNode }[]
}> = ({
  sheetActive = false,
  closeAction,
  tagsSelected,
  heading = "Available tags",
  tags = [],
}) => {
  const [selected, setSelected] = useState([])

  const toggleSheetActive = useCallback(() => {
    if (typeof closeAction === "function") {
      closeAction()
    }
    if (typeof tagsSelected === "function") {
      tagsSelected(selected)
    }
    setSelected([])
  }, [selected])
  const handleSelectedChange = useCallback((value) => setSelected(value), [])
  const selectAll = useCallback(() => setSelected(tags.map((t) => t.value)), [
    tags,
  ])

  return (
    <Sheet open={sheetActive} onClose={toggleSheetActive}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            // alignItems: "center",
            borderBottom: "1px solid #DFE3E8",
            // display: "flex",
            // justifyContent: "space-between",
            padding: "1.6rem",
            // width: "100%",
          }}
        >
          <Stack>
            <Stack.Item fill>
              <Heading>{heading}</Heading>
            </Stack.Item>
            <Button plain onClick={selectAll}>
              Select all
            </Button>
            <Button
              accessibilityLabel="Cancel"
              icon={MobileCancelMajorMonotone}
              onClick={toggleSheetActive}
              plain
            />
          </Stack>
        </div>
        <Scrollable style={{ padding: "1.6rem", height: "100%" }}>
          <ChoiceList
            title="Tags"
            name="salesChannelsList"
            //   choices={salesChannels}
            choices={tags}
            selected={selected}
            titleHidden
            allowMultiple
            onChange={handleSelectedChange}
          />
        </Scrollable>
        <div
          style={{
            alignItems: "center",
            borderTop: "1px solid #DFE3E8",
            display: "flex",
            justifyContent: "space-between",
            padding: "1.6rem",
            width: "100%",
          }}
        >
          <Button onClick={toggleSheetActive}>Cancel</Button>
          <Button primary onClick={toggleSheetActive}>
            Done
          </Button>
        </div>
      </div>
    </Sheet>
  )
}
