import React from "react"
import stc from "string-to-color"
export const TagIcon: React.FC<{
  tag?: string
}> = ({ tag }) => {
    const color = stc(tag);
  return (
    <span className="Custom-Polaris-Icon">
      <svg
        viewBox={`0 0 20 20`}
        className="Custom-Polaris-Icon__Svg"
        focusable="false"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle fill={color} cx="10" cy="10" r="5" />
      </svg>
    </span>
  )
}
