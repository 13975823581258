import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Checkbox,
  Layout,
  Page,
  Card,
  Tag,
  Stack,
  TextStyle,
  Heading,
  Banner,
} from "@shopify/polaris"
import { Loading } from "@shopify/app-bridge-react"
import firebaseLib from "firebase/app"
// import firebase as FIREBASE_LIB from "firebase/app"
import { AppProps, ShopifyAppBrideState } from "../../helpers/app-props"
import { Context } from "@shopify/app-bridge-react"
import { OrderSpentRange } from "../../templates/components/order-spent-range"
import { TagSheet } from "../../templates/components/tag-sheet"
import { DateSelector } from "../../templates/components/date-selector"
import { OrderDataTable } from "../../templates/components/order-table"
import { formatMoney } from "../../helpers/formatting"
import { HeatMappableOrder, Order } from "../../helpers/types"
import { useQuery, gql } from "@apollo/client"
// import { useFirebase } from "../../providers/firebase"
import { SkeletonApp } from "../../templates/components/skeleton-app"
import { TagIcon } from "../../templates/components/tag-icon"
import { colorGreenDark } from "@shopify/polaris-tokens"
import { OrderMap } from "../../templates/components/order-map"
import { getAppConfig } from "../../providers/app-config"
import { useFirebase } from "../../helpers/firebase/loader"
import { getAuthContext } from "../../providers/auth"
import { OrderMappableConverter } from "../../helpers/firebase/order-to-mappable.converter"
import googleMapReact from "google-map-react"

const cleanId = (id: string) => parseInt(`${id.match(/[0-9 , \.]+/g)}`)

const inBoundingBox = <T extends { lat: number; lng: number }>(
  bottomLeft: T,
  topRight: T,
  point: T
) => {
  let isLongInRange: boolean
  let isLatiInRange: boolean
  isLongInRange = point.lng >= bottomLeft.lng && point.lng <= topRight.lng
  isLatiInRange = point.lat >= bottomLeft.lat && point.lat <= topRight.lat
  return isLongInRange && isLatiInRange
}
// const gradient = [
//   "rgba(0, 255, 255, 0)",
//   "rgba(0, 255, 255, 1)",
//   "rgba(0, 191, 255, 1)",
//   "rgba(0, 127, 255, 1)",
//   "rgba(0, 63, 255, 1)",
//   "rgba(0, 0, 255, 1)",
//   "rgba(0, 0, 223, 1)",
//   "rgba(0, 0, 191, 1)",
//   "rgba(0, 0, 159, 1)",
//   "rgba(0, 0, 127, 1)",
//   "rgba(63, 0, 91, 1)",
//   "rgba(127, 0, 63, 1)",
//   "rgba(191, 0, 31, 1)",
//   "rgba(255, 0, 0, 1)",
// ]
const COLLECTIONS = gql`
  query {
    collections(first: 100) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`
const Index: React.FC<AppProps> = (props) => {
  const bridgeContext = useContext(Context)
  // const firebaseContext = useFirebase()
  // const firebaseContext = useFirebase()
  const appConfig = getAppConfig()
  const firebase = useFirebase()
  const authContext = getAuthContext()
  // console.log({bridgeContext})
  // const { firebase, shop } = props
  // const {shop } = props
  const [shop] = useState(appConfig.shop)
  if (!shop) {
    // context?.getState().then((state) => {
    //   console.log({state})
    // })
    console.log({ props, appConfig })
    return <p>Could not get firebase or shop</p>
  }

  const [isLoading, setIsLoading] = useState(true)
  const [appDisplayName, setAppDisplayName] = useState<string>()
  const [
    importStatusCallableFunction,
    setImportStatusCallableFunction,
  ] = useState<firebaseLib.functions.HttpsCallable>()
  const [configured, setIsConfigured] = useState(false)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [labeledTags, setLabeledTags] = useState<
    { value: string; label: React.ReactNode }[]
  >()
  const [excludeTags, setExcludeTags] = useState<string[]>([])
  const [resultCount, setResultCount] = useState(0)
  const filteredDataUpdated = useRef(false)
  const [tagSheetActive, setTagSheetActive] = useState(false)
  const [filterMapBounds, setFilterMapBounds] = useState(false)
  type Point = { lat: number; lng: number }
  const [mapBounds, setMapBounds] = useState<{
    nw: Point
    se: Point
    sw: Point
    ne: Point
  }>()
  const [excludeTagSheetActive, setExcludeTagSheetActive] = useState(false)
  const [collectionSheetActive, setCollectionSheetActive] = useState(false)
  const [smallestOrderAmount, setSmallestOrderAmount] = useState(0)
  const [largestOrderAmount, setLargestOrderAmount] = useState(100)
  const [orderAmountInitialValues, setOrderAmountInitialValues] = useState<
    [number, number]
  >([smallestOrderAmount, largestOrderAmount])
  const [minOrderAmount, setMinOrderAmount] = useState(smallestOrderAmount)
  const [maxOrderAmount, setMaxOrderAmount] = useState(largestOrderAmount)

  const [
    { start: selectedStartDate, end: selectedEndDate },
    setSelectedDates,
  ] = useState({
    start: new Date(2020, 0),
    end: new Date(2020, 2),
  })
  // const [selectedEndDate, setSelectedEndDate] = useState(new Date(2020, 1));

  const [orderCount, setOrderCount] = useState<number>()
  const [customerCount, setCustomerCount] = useState(0)
  const [totalValue, setTotalValue] = useState(0)

  const [markersVisible, setMarkersVisible] = useState(false)
  const [showHeatMap, setShowHeatMap] = useState(true)

  const [priceWeight, setPriceWeight] = useState(false)
  const [positiveSalesOnly, setPositiveSalesOnly] = useState(true)
  const [nonePositiveSalesOnly, setNonePositiveSalesOnly] = useState(false)
  const [selectedCollections, setSelectedCollections] = useState<string[]>([])
  const {
    loading: collectionsLoading,
    error: collectionsError,
    data: collectionsData,
  } = useQuery(COLLECTIONS)
  const [collectionChoices, setCollectionChoices] = useState<
    [
      {
        label: string
        value: string
      }
    ]
  >()
  const [dataPoints, setDataPoints] = useState<HeatMappableOrder[]>([])
  const [filteredDataPoints, setFilteredDataPoints] = useState(dataPoints)

  // const callImportOrders = firebaseLib
  //   .functions()
  //   .httpsCallable("shopifyImportOrders")

  // const callCheckStatus = firebaseLib
  //   .functions()
  //   .httpsCallable("shopifyCheckImportOrdersStatus")

  const removeSelectedTag = useCallback(
    (tag) => (newChecked: boolean, id: string) => {
      const newTags = selectedTags
      // console.log("Updating tags", { newChecked, id, tag, newTags })
      if (newChecked) {
        newTags.push(tag)
      } else {
        const index = newTags.indexOf(tag)
        if (index > -1) {
          newTags.splice(index, 1)
        }
      }
      // console.log({ newTags })
      setSelectedTags([...new Set(newTags)])
    },
    [selectedTags]
  )
  const addTags = useCallback(
    (tags: string[]) => {
      const combined = [...selectedTags, ...tags]
      setSelectedTags([...new Set(combined)])
    },
    [selectedTags]
  )
  const addExcludeTags = useCallback(
    (tags: string[]) => {
      const combined = [...excludeTags, ...tags]
      setExcludeTags([...new Set(combined)])
    },
    [excludeTags]
  )
  const updateExcludeTags = useCallback(
    (tag) => (newChecked: boolean, id: string) => {
      const newTags = excludeTags
      // console.log("Updating exclude tags", { newChecked, id, tag, newTags })
      if (newChecked) {
        newTags.push(tag)
      } else {
        const index = newTags.indexOf(tag)
        if (index > -1) {
          newTags.splice(index, 1)
        }
      }
      // console.log({ newTags })
      setExcludeTags([...new Set(newTags)])
    },
    [excludeTags]
  )

  const addSelectedCollection = useCallback(
    (ids: string[]) => {
      const combined = [...selectedCollections, ...ids]
      setSelectedCollections([...new Set(combined)])
    },
    [selectedCollections]
  )
  const removeSelectedCollection = useCallback(
    (id) => {
      const newTags = selectedCollections
      const index = newTags.indexOf(id)
      if (index > -1) {
        newTags.splice(index, 1)
      }
      setSelectedCollections([...new Set(newTags)])
    },
    [selectedCollections]
  )
  const clearSelectedCollections = useCallback(() => {
    setSelectedCollections([])
  }, [])

  const updateMinMax = useCallback(([min, max]) => {
    setMinOrderAmount(min)
    setMaxOrderAmount(max)
  }, [])

  const handleCollectionChange = useCallback(
    (value) => setSelectedCollections(value),
    []
  )

  const handleMapChange = useCallback(
    (changes: googleMapReact.ChangeEventValue) => {
      setMapBounds(changes.bounds)
    },
    []
  )

  // useEffect(() => {
  //   if (bridgeContext) {
  //     // No async functions in use effect? lets IIFE it up
  //     (async () => {
  //       const bridgeState: ShopifyAppBrideState  = await bridgeContext.getState()
  //       console.log({bridgeState})
  //       const {appInfo} = bridgeState.titleBar
  //       const {name} = appInfo;
  //       setAppDisplayName(name);
  //     })();

  //   }
  // }, [bridgeContext])

  useEffect(() => {
    if (!firebase) {
      return
    }
    /**
     * Run callable functions, to check status and fire any waiting events that
     * are still not completed
     *
     * Eventually do something with the response
     */
    // const callImportOrders = firebase.functions().httpsCallable("shopifyImportOrders")
    // callImportOrders().then((importOrdersResult) => {
    //       console.log({importOrdersResult})
    // }).catch((error) => {
    //       console.error({importResultError: error})
    //   });

    // const callCheckStatus = firebase.functions().httpsCallable("shopifyCheckImportOrdersStatus")
    // callCheckStatus().then((checkStatusResult) => {
    //       console.log({checkStatusResult})
    // }).catch((error) => {
    //       console.error({statusResultError: error})
    //   });
    // setImportStatusCallableFunction(callCheckStatus);
    /**
     * Setup a snapshot observer and unsubscribe function
     */
    const observer = {
      next: (snap: firebaseLib.firestore.QuerySnapshot) => {
        if (!snap.empty) {
          const orders = snap.docs
            .map((doc) => {
              const node = doc.data() as Order
              // if (
              //   node &&
              //   node.shippingAddress &&
              //   node.shippingAddress.latitude
              // ) {
              //   return {
              //     lat: node.shippingAddress.latitude,
              //     lng: node.shippingAddress.longitude,
              //     orderAmount: parseFloat(
              //       node.subtotalPriceSet.shopMoney.amount
              //     ),
              //     createdAt: node.createdAt.toDate(),
              //     customer: node.customer,
              //     name: node.name,
              //     id: node.id,
              //     tags: node.tags,
              //     lineItems: node.lineItems,
              //     weight: priceWeight
              //       ? parseFloat(node.subtotalPriceSet.shopMoney.amount)
              //       : 1,
              //   }
              // }

              if (node) {
                return {
                  ...node,
                  weight: priceWeight ? node.subtotalPrice : 1,
                }
              }
            })
            .filter((v) => typeof v !== "undefined")
            .flat()
          // console.log({ orders })
          setDataPoints(orders)
          setOrderCount(orders.length)
          setIsLoading(false)
          setIsConfigured(true)
        } else {
          console.log("Orders empty")
          setDataPoints([])
          setOrderCount(0)
          // is configured is not a cool name, like its all about if records are found
          setIsConfigured(false)
          setIsLoading(false)
        }
      },
      error: (error: firebaseLib.firestore.FirestoreError) => {
        const { code, message } = error
        console.log({ code, message })

        switch (code) {
          case "permission-denied":
            setDataPoints([])
            setOrderCount(0)
            setIsConfigured(false)
            setIsLoading(false)
            break

          default:
            break
        }

        return console.error(error)
      },
    }
    const unsubscribe = firebase
      .firestore()
      .collection(`/businesses/${shop}/orders`)
      .withConverter(OrderMappableConverter)
      .orderBy("createdAt", "desc")
      .onSnapshot(observer)
    // .catch((reason) => console.warn("Unable to load", { reason }))
    return unsubscribe
    // }, [setDataPoints, setOrderCount, setIsLoading])
  }, [firebase])

  // useEffect(() => {
  //   const paramType = typeof importStatusCallableFunction;
  //   console.log('import status callable changed', {paramType})
  //   if (importStatusCallableFunction) {
  //     importStatusCallableFunction().then((result) => {
  //       console.log({result})
  //     }).catch((error) => {
  //         console.error({statusResultError: error})
  //     });
  //     // (async () => {
  //     //   try {
  //     //   const statusResult = await importStatusCallableFunction({});
  //     //   console.log({statusResult})
  //     //   } catch (error) {
  //     //     console.error({statusResultError: error})
  //     //   }
  //     // })()
  //   }
  // }, [importStatusCallableFunction])

  useEffect(() => {
    let newTags: string[] = []
    // let markers: any[] = []
    if (dataPoints.length <= 0) {
      return
    }
    // const { map, maps } = mapObjects
    let largest = largestOrderAmount
    dataPoints.forEach((order) => {
      if (order && typeof order === "object") {
        const { customer, subtotalPrice = 0 } = order
        if (customer && typeof customer === "object") {
          const { tags } = customer
          if (tags && Array.isArray(tags)) {
            tags.forEach((tag) => {
              if (!newTags.includes(tag)) {
                newTags.push(tag)
              }
            })
          }
          // const { marker } = createMarker(maps, map, order, shop)
          // markers.push(marker)
        }
        if (subtotalPrice > largest) {
          largest = subtotalPrice
        }
      }
    })
    // console.log({ newTags })
    setMaxOrderAmount(largest)
    setLargestOrderAmount(largest)
    setOrderAmountInitialValues([0, largest])
    setTags(newTags)
    // setMarkers(markers)
  }, [dataPoints])

  useEffect(() => {
    const update = [...tags].sort().map((tag) => ({
      value: tag,
      label: (
        <Stack spacing="extraTight">
          <TagIcon tag={tag} /> <span>{tag}</span>
        </Stack>
      ),
    }))
    setLabeledTags(update)
  }, [tags])

  // Auth change marker visibility, I liked...
  // useEffect(() => {
  //   if (zoom >= markersVisibleAt) {
  //     setMarkersVisible(true)
  //   } else {
  //     if (prevVisibleInfoWindow) {
  //       prevVisibleInfoWindow.close()
  //     }
  //     setMarkersVisible(false)
  //   }
  // }, [zoom])

  useEffect(() => {
    const startDate = selectedStartDate
    const endDate = selectedEndDate
    const filterDates = true

    let filteredPoints = dataPoints.filter((point) => {
      return selectedTags.length > 0 && point.customer
        ? selectedTags.every((t) =>
            point.customer ? point.customer.tags.includes(t) : false
          )
        : point.customer !== null
    })
    if (filterMapBounds && mapBounds) {
      console.log("Filtering on Bounds", mapBounds)
      filteredPoints = filteredPoints.filter((p) =>
        inBoundingBox(mapBounds.sw, mapBounds.ne, {
          lat: p.latitude,
          lng: p.longitude,
        })
      )
    }

    // TODO: combine into one loop
    // console.log({filteredPoints})
    if (filterDates && startDate) {
      filteredPoints = filteredPoints.filter((v) => v.createdAt >= startDate)
    }

    if (filterDates && endDate) {
      filteredPoints = filteredPoints.filter((v) => v.createdAt <= endDate)
    }

    if (excludeTags.length > 0) {
      filteredPoints = filteredPoints.filter((v) =>
        v.customer
          ? v.customer.tags.every((t) => !excludeTags.includes(t))
          : false
      )
    }

    if (selectedCollections.length > 0) {
      filteredPoints = filteredPoints.filter((v) =>
        v.lineItems.some((i) =>
          selectedCollections.some((c) => i.collections.includes(c))
        )
      )
    }

    if (positiveSalesOnly) {
      filteredPoints = filteredPoints.filter((v) => (v.subtotalPrice || 0) > 0)
    }
    if (nonePositiveSalesOnly) {
      filteredPoints = filteredPoints.filter((v) => (v.subtotalPrice || 0) <= 0)
    }

    if (minOrderAmount) {
      filteredPoints = filteredPoints.filter((v) =>
        typeof v.subtotalPrice === "number"
          ? v.subtotalPrice >= minOrderAmount
          : false
      )
    }

    if (maxOrderAmount) {
      filteredPoints = filteredPoints.filter((v) =>
        typeof v.subtotalPrice === "number"
          ? v.subtotalPrice <= maxOrderAmount
          : false
      )
    }

    if (priceWeight) {
      filteredPoints = filteredPoints.map((c) => ({
        ...c,
        weight: c.subtotalPrice,
      }))
    }
    // TODO: Add exclude filtered list, array from set to make unique while at it
    const shownIds = Array.from(
      new Set(
        filteredPoints.map((order) =>
          order.customer ? order.customer.id : null
        )
      )
    )
    const displayedValue = filteredPoints.reduce(
      (p, c) => p + (c.subtotalPrice || 0),
      0
    )
    // const largestFilteredOrderAmount = filteredPoints.reduce(
    //   (p, c) => c.orderAmount ? (c.orderAmount > p ? c.orderAmount : p) : p,
    //   11 // Start with step size + 1
    // )

    setResultCount(filteredPoints.length)
    setCustomerCount(shownIds.length)
    setTotalValue(displayedValue)
    setFilteredDataPoints(filteredPoints)
    filteredDataUpdated.current = true
    // setOrderAmountInitialValues([0,largestFilteredOrderAmount]);
    // setLargestOrderAmount(largestFilteredOrderAmount);

    // console.log({
    //   filteredPoints,
    //   displayedValue,
    //   selectedCollections,
    //   positiveSalesOnly,
    //   nonePositiveSalesOnly,
    // })
  }, [
    dataPoints,
    nonePositiveSalesOnly,
    positiveSalesOnly,
    priceWeight,
    markersVisible,
    // mapObjects,
    // markers,
    excludeTags,
    selectedTags,
    selectedEndDate,
    selectedStartDate,
    minOrderAmount,
    maxOrderAmount,
    selectedCollections,
    // filterMapBounds,
    // mapBounds,
  ])

  useEffect(() => {
    if (!collectionsData) {
      return
    }
    const { collections = { edges: [] } } = collectionsData
    const choices = collections.edges.map(({ node }: any) => {
      return {
        label: (
          <Stack spacing="extraTight">
            <TagIcon tag={node.title} /> <span>{node.title}</span>
          </Stack>
        ),
        value: typeof node.id === "string" ? cleanId(node.id) : node.id,
      }
    })
    setCollectionChoices(choices)
  }, [collectionsData])

  const signOut = useCallback(() => {
    if (firebase) {
      firebase.auth().signOut()
    }
  }, [firebase])

  if (!authContext.isAuthenticated) {
    return (
      <>
        <Loading />
        Authenticating
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <Loading />
        <SkeletonApp />
      </>
    )
  }

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          {!isLoading && configured === false && (
            <div style={{ marginBottom: "2rem" }}>
              <Banner title="Loading orders" status="info" onDismiss={signOut}>
                <p>
                  Thank you for installing {appDisplayName}. Orders are loading
                  and should be available soon.
                </p>
              </Banner>
              {/* <Frame> */}
              <Loading />
              {/* </Frame> */}
            </div>
          )}
          <Card>
            <Card.Section>
              <Stack distribution="fillEvenly">
                <Stack vertical={true} alignment="center" spacing="tight">
                  <TextStyle variation="subdued">Showing</TextStyle>
                  <Heading>{resultCount}</Heading>
                  <TextStyle variation="subdued">
                    of {orderCount} orders
                  </TextStyle>
                </Stack>
                <Stack vertical={true} alignment="center" spacing="tight">
                  <TextStyle variation="subdued">Total spent</TextStyle>
                  <Heading>${`${formatMoney(totalValue)}`}</Heading>
                  <TextStyle variation="subdued">
                    from {customerCount} customers
                  </TextStyle>
                </Stack>
                <Stack vertical={true} alignment="center" spacing="tight">
                  <TextStyle variation="subdued">Average order value</TextStyle>
                  <Heading>
                    ${`${formatMoney(totalValue / customerCount)}`}
                  </Heading>
                </Stack>
              </Stack>
            </Card.Section>
            {/* <Card.Section> */}
            <OrderMap
              shop={shop}
              // filteredPoints={filteredDataPoints}
              orders={filteredDataPoints}
              // orders={dataPoints}
              showMarkers={markersVisible}
              showHeatMap={showHeatMap}
              changeHandler={handleMapChange}
            />
          </Card>

          <OrderDataTable
            orders={filteredDataPoints}
            shop={shop}
            valueParams={{
              collections: [
                { id: "gid://shopify/Collection/162533769347", value: 0.05 },
              ],
            }}
            collectionParam={{
              selected: selectedCollections,
              choices: collectionChoices,
            }}
          />
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Options">
            <Card.Section title="Date range">
              <DateSelector
                defaultRange="thisMonth"
                onUpdate={setSelectedDates}
                customStartDate={selectedStartDate}
                customEndDate={selectedEndDate}
              />
            </Card.Section>
            <Card.Section title="Order amount">
              {
                // Checking for a value less than 100 is lame but works
              }
              {/* {orderAmountInitialValues[1] !== 100 && ( */}
              <OrderSpentRange
                min={smallestOrderAmount}
                max={largestOrderAmount}
                initialValue={orderAmountInitialValues}
                onUpdate={updateMinMax}
              />
              {/* )} */}
            </Card.Section>
            <Card.Section
              title="Required tags"
              actions={[
                {
                  content: "Clear",
                  onAction: () => setSelectedTags([]),
                  disabled: selectedTags.length == 0,
                },
                {
                  content: "Add",
                  onAction: () => setTagSheetActive(true),
                },
              ]}
            >
              {selectedTags.length === 0 && (
                <TextStyle variation="subdued">All tags included</TextStyle>
              )}
              <Stack spacing="extraTight">
                {selectedTags.map((tag) => (
                  <Tag
                    key={"rt-" + tag}
                    onRemove={() => {
                      removeSelectedTag(tag)(false, "random")
                    }}
                  >
                    {/* {tag} */}
                    {labeledTags &&
                      (labeledTags.filter((v) => v.value === tag)[0]
                        .label as string)}
                  </Tag>
                ))}
              </Stack>
            </Card.Section>
            <Card.Section
              title="Excluded tags"
              actions={[
                {
                  content: "Clear",
                  onAction: () => setExcludeTags([]),
                  disabled: excludeTags.length == 0,
                },
                {
                  content: "Add",
                  onAction: () => setExcludeTagSheetActive(true),
                },
              ]}
            >
              {excludeTags.length === 0 && (
                <TextStyle variation="subdued">No tags excluded</TextStyle>
              )}
              <Stack spacing="extraTight">
                {excludeTags.map((tag) => (
                  <Tag
                    key={"et-" + tag}
                    onRemove={() => {
                      updateExcludeTags(tag)(false, "random")
                    }}
                  >
                    {/* {tag} */}
                    {labeledTags &&
                      (labeledTags.filter((v) => v.value === tag)[0]
                        .label as string)}
                    {/* <TagIcon tag={tag} /> <span>{tag}</span> */}
                  </Tag>
                ))}
              </Stack>
            </Card.Section>
            {/* <Card.Section>
              <ChoiceList
                allowMultiple
                title="Product collection"
                choices={collectionChoices}
                selected={selectedCollections}
                onChange={handleCollectionChange}
              />
            </Card.Section> */}
            <Card.Section
              title="Product collections"
              actions={[
                {
                  content: "Clear",
                  onAction: clearSelectedCollections,
                  disabled: selectedCollections.length == 0,
                },
                {
                  content: "Add",
                  onAction: () => setCollectionSheetActive(true),
                },
              ]}
            >
              {selectedCollections.length === 0 && (
                <TextStyle variation="subdued">
                  All collections included
                </TextStyle>
              )}
              <Stack spacing="extraTight">
                {selectedCollections.map((id) => (
                  <Tag
                    onRemove={() => {
                      removeSelectedCollection(id)
                    }}
                    key={"ic-" + id}
                  >
                    {collectionChoices &&
                      collectionChoices.filter((v) => v.value === id)[0].label}
                  </Tag>
                ))}
              </Stack>
            </Card.Section>
            <Card.Section subdued>
              <Stack vertical spacing="extraTight">
                <Checkbox
                  label="Weight sale"
                  checked={priceWeight}
                  onChange={setPriceWeight}
                  helpText="Use the total sale for intensity of heat in map"
                />
                <Checkbox
                  // label="> 0"
                  checked={positiveSalesOnly}
                  onChange={setPositiveSalesOnly}
                  label="Show only sales over $0.00"
                />
                <Checkbox
                  // label="<= 0"
                  checked={nonePositiveSalesOnly}
                  onChange={setNonePositiveSalesOnly}
                  label="Show only sales equal to $0.00"
                />
                <Checkbox
                  label="Show Markers"
                  checked={markersVisible}
                  onChange={setMarkersVisible}
                  // helpText="Display markers on the mak"
                />
                <Checkbox
                  label="Show Heatmap"
                  checked={showHeatMap}
                  onChange={setShowHeatMap}
                  helpText="Add weighted visualization effect"
                />
                <Checkbox
                  label="Bind to map"
                  checked={filterMapBounds}
                  onChange={setFilterMapBounds}
                  helpText="Show totals for visible orders on map"
                />
              </Stack>
            </Card.Section>
          </Card>
          {/*
          <Card sectioned subdued>
            <Button
              onClick={async () => {
                console.log("Import Orders")
                const result = await callImportOrders()
                console.log({ result })
              }}
            >
              Queue Import
            </Button>
            <Button
              onClick={async () => {
                console.log("Check status")
                const result = await callCheckStatus()
                console.log({ result })
              }}
            >
              Import
            </Button>
            <TextContainer>
            <TextStyle variation="subdued">Probably something you don't need to worry about</TextStyle>
            </TextContainer>
          </Card>
           */}
        </Layout.Section>
      </Layout>
      <TagSheet
        sheetActive={tagSheetActive}
        closeAction={() => setTagSheetActive(false)}
        tags={labeledTags}
        tagsSelected={addTags}
      />
      <TagSheet
        sheetActive={excludeTagSheetActive}
        closeAction={() => setExcludeTagSheetActive(false)}
        tags={labeledTags}
        tagsSelected={addExcludeTags}
      />
      <TagSheet
        sheetActive={collectionSheetActive}
        closeAction={() => setCollectionSheetActive(false)}
        tags={collectionChoices}
        tagsSelected={addSelectedCollection}
        heading="Product collections"
      />
    </Page>
  )
}

//export default withFirebase(Index)
export default Index
