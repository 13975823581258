import React, { useState, useCallback } from "react";
import {Button, Popover, ActionList } from "@shopify/polaris";

export const CustomerPopupButton: React.FC<{
    title: string
}> = ({children: popupChildren, title}) => {
    const [popoverActive, setPopoverActive] = useState(false);
  
    const togglePopoverActive = useCallback(
      () => setPopoverActive((popoverActive) => !popoverActive),
      [],
    );
  
    const activator = (
        <Button onClick={togglePopoverActive} disclosure plain>{title}</Button>
    );
  
    return (
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
          children={popupChildren}
          sectioned={true}
        ></Popover>
    );
  }