import React, { useState, useCallback, useContext } from "react"
import {
  TextContainer,
  Heading,
  Button,
  Tag,
  Stack,
  TextStyle,
} from "@shopify/polaris"
import { Context } from "@shopify/app-bridge-react"
import { Redirect } from "@shopify/app-bridge/actions"
import stc from "string-to-color"
import { Order } from "../../helpers/types"
import { TagIcon } from "./tag-icon"

// if (!context) {
//     return <>No context</>;
// }
export const CustomerInfo: React.FC<{
  customer?: Order["customer"]
}> = ({ customer }) => {
  const context = useContext(Context)
  const redirectToCustomer = useCallback(() => {
    if (!context || !customer) {
      console.error("No context or customer", { context, customer })
      return
    }
    const id = `${customer.id}`
    const redirect = Redirect.create(context)
    redirect &&
      redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
        name: Redirect.ResourceType.Customer,
        resource: { id },
      })
  }, [context])

  if (!customer) {
    return (
      <Stack vertical>
        <TextContainer>
          <TextStyle variation="strong">Unknown</TextStyle> <br />
          {/* <TextStyle variation="subdued">
          {customer.ordersCount} order{customer.ordersCount > 1 ? "s" : ""} starting {customer.lifetimeDuration} ago
        </TextStyle> */}
        </TextContainer>
      </Stack>
    )
  }
  return (
    <Stack vertical>
      <TextContainer>
        <TextStyle variation="strong">
          {customer.firstName} {customer.lastName}
        </TextStyle>{" "}
        {/* {JSON.stringify({ customer })} */}
        <br />
        <TextStyle variation="subdued">
          {customer.ordersCount} order
          {customer.ordersCount && customer.ordersCount > 1 ? "s" : ""} with a
          total of {customer.totalSpent}
          {/* {customer.lifetimeDuration
            ? `starting ${customer.lifetimeDuration} ago`
            : ""} */}
        </TextStyle>
      </TextContainer>
      {Array.isArray(customer.tags) && (
        <Stack spacing="extraTight">
          {customer.tags.map((tag) => (
            // <Tag key={tag}>{tag}</Tag>
            <Tag key={tag}>
              {
                ((
                  <Stack spacing="extraTight">
                    <TagIcon tag={tag} /> <span>{tag}</span>
                  </Stack>
                ) as unknown) as string
              }
            </Tag>
          ))}
        </Stack>
      )}
      <Button onClick={redirectToCustomer}>View Customer</Button>
    </Stack>
  )
}
