import React, { useCallback, useContext, useEffect, useState } from "react"
import {
  Card,
  DataTable,
  Link as PolarisURL,
  Pagination,
  Stack,
  Tag,
} from "@shopify/polaris"
import { formatMoney } from "../../helpers/formatting"
import { Context, Modal } from "@shopify/app-bridge-react"
import { Redirect } from "@shopify/app-bridge/actions"
import { HeatMappableOrder } from "../../helpers/types"
import {
  calcOrderValue,
  calcOrderValues,
  ValueParams,
} from "../../helpers/value-calculator"
import { CustomerPopupButton } from "./customer-popup-button"
import { CustomerInfo } from "./customer-info"
import { OrderInfo } from "./order-info"
const sortCurrency = (rows: any[][], index: number, direction: string) => {
  return [...rows].sort((rowA, rowB) => {
    // const a = rowA[index]
    // const b = rowB[index]
    const amountA = parseFloat(`${rowA[index]}`.replace(/[^0-9\.]/g, ""))
    const amountB = parseFloat(`${rowB[index]}`.replace(/[^0-9\.]/g, ""))

    return direction === "descending" ? amountB - amountA : amountA - amountB
  })
}

export const OrderDataTable: React.FC<{
  orders: HeatMappableOrder[]
  shop: string
  valueParams?: ValueParams
  collectionParam?: {
    selected: string[]
    choices?: { value: string; label: string }[]
  }
}> = ({ orders = [], shop, valueParams, collectionParam }) => {
  const context = useContext(Context)
  const baseColumnCount = 4
  const injectCollectionsAt = 3 // Column of collections;
  // const tableDisplayCap = 5
  const defaultHeadings = ["Order", "Date", "Customer", "Items", "Total"]
  const defaultColumnTypes = ["text", "text", "text", "numeric", "numeric"]
  if (!shop || !context) {
    return <>error-getting-props</>
  }
  const redirect = Redirect.create(context)
  const [totalItems, setTotalItems] = useState(0)
  const [totalSales, setTotalSales] = useState(0)
  const [totalValue, setTotalValue] = useState(0)
  const [pagerPage, setPagerPage] = useState(0)
  const [tableDisplayCap, setTableDisplayCap] = useState(50)
  const [allRowCount, setAllRowCount] = useState(0)
  const [currentlySortedColumn, setCurrentlySortedColumn] = useState(
    baseColumnCount - 1
  )
  const [sortedRows, setSortedRows] = useState([])
  const [visibleRows, setVisibleRows] = useState([])
  const [constructedRows, setConstructedRows] = useState(null)
  const [headings, setHeadings] = useState(defaultHeadings)
  const [columnTypes, setColumnTypes] = useState(defaultColumnTypes)
  const [initiallySortedRows, setInitiallySortedRows] = useState([])
  const [selectedCollections, setSelectedCollections] = useState<string[]>([])
  const [totals, setTotals] = useState<string | number[]>([])
  const [collectionTotals, setCollectionTotals] = useState<{
    [id: string]: string | number
  }>({})

  // useEffect(() => {
  //   setValueCollections(
  //     !!(
  //       collectionParam &&
  //       Array.isArray(collectionParam.selected) &&
  //       collectionParam.selected.length > 0
  //     )
  //   )
  // }, [collectionParam])

  useEffect(() => {
    const newHeaders = [...defaultHeadings]
    const columnTypes = [...defaultColumnTypes]
    if (collectionParam && collectionParam.selected.length > 0) {
      const collectionHeaders = collectionParam.selected.map(
        (id) =>
          collectionParam.choices &&
          collectionParam.choices.find((c) => c.value === id)?.label
      )

      const newTypes = collectionHeaders.map((_) => "numeric")
      // newHeaders.splice(injectCollectionsAt, 0, ...collectionHeaders, 'Value')
      newHeaders.splice(injectCollectionsAt, 0, ...collectionHeaders)
      columnTypes.splice(injectCollectionsAt, 0, ...newTypes, "numeric")
    }

    setSelectedCollections(
      collectionParam?.selected ? collectionParam.selected : []
    )
    setHeadings(newHeaders)
    setColumnTypes(columnTypes)
  }, [collectionParam])

  useEffect(() => {
    // So much duplication of effort here :( could be more efficient
    const orderValueParams = {
      collections: selectedCollections.map((id: number) => {
        // Get the value from the collections params or false
        const value =
          valueParams && Array.isArray(valueParams.collections)
            ? valueParams.collections.find((v) => v.id === id)?.value || false
            : false
        return { id, value }
      }),
    }
    const totalSales = orders.reduce((p, c) => p + (c.subtotalPrice || 0), 0)
    const totalItems = orders.reduce(
      (p, c) =>
        c.lineItems
          ? p + [...c.lineItems].reduce((p, c) => p + (c.quantity || 0), 0)
          : 0,
      0
    )
    const totalValue = orders.reduce(
      (p, c) => p + calcOrderValue(c, orderValueParams),
      0
    )
    // Counter to store entire collections values...
    let counter: { [collection: string]: number } = selectedCollections.reduce(
      (p, c) => ({ ...p, [c]: 0 }),
      {}
    )
    const orderRows = [...orders].map((order) => {
      // const orderValue = calcOrderValue(order, orderValueParams)
      const { customer } = order
      const columns = [
        <CustomerPopupButton title={order.name}>
          <OrderInfo order={order} />
        </CustomerPopupButton>,
        new Date(order.createdAt).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }),
        customer ? (
          <CustomerPopupButton
            title={
              customer
                ? `${customer.firstName || ""}  ${
                    customer.lastName || ""
                  }`.trim()
                : "unknown"
            }
          >
            <CustomerInfo customer={customer} />
          </CustomerPopupButton>
        ) : (
          "Unknown"
        ),
        order.lineItems
          ? order.lineItems.reduce((p, c) => p + (c.quantity || 0), 0)
          : 0,
        `$${formatMoney(order.subtotalPrice || 0)}`,
      ]
      // If we have selected collections inject new rows with data for each
      if (selectedCollections.length > 0) {
        const orderValues = calcOrderValues(order, orderValueParams)
        const values = selectedCollections.map((id: string) => {
          const data = orderValues.find((v) => id === v.id)
          if (data) {
            counter[id] += data.value
            return `$${formatMoney(data.value)}`
          } else {
            return 0
          }
        })
        // const orderValue = orderValues.reduce((p, c) => p + c.value, 0);
        // columns.splice(injectCollectionsAt, 0, ...values, `$${formatMoney(orderValue)}`)
        columns.splice(injectCollectionsAt, 0, ...values)
        setCollectionTotals(counter)
      }
      // console.log({ columns })
      return columns
    })
    setTotalSales(totalSales)
    setTotalItems(totalItems)
    setTotalValue(totalValue)
    setAllRowCount(orderRows.length)
    // setInitiallySortedRows(sortCurrency(rows, currentlySortedColumn, "descending").slice(0, tableDisplayCap))
    setConstructedRows(orderRows)
    const sorted = sortCurrency(orderRows, currentlySortedColumn, "descending")
    setSortedRows(sorted)
    setVisibleRows(sorted.slice(pagerPage * tableDisplayCap, tableDisplayCap))
    // setSortedRows(rows.slice(0, tableDisplayCap))
  }, [orders, selectedCollections, currentlySortedColumn])

  useEffect(() => {
    const totals = ["","", "", totalItems, `$${formatMoney(totalSales)}`]
    // console.log({ collectionTotals }, selectedCollections.length)
    if (selectedCollections.length > 0) {
      const values = selectedCollections.map((id) => {
        const value = collectionTotals[id]
        switch (typeof value) {
          case "number":
            return `$${formatMoney(value)}`
          case "string":
            return value
          default:
            return "-"
        }
      })
      // totals.splice(injectCollectionsAt, 0, ...values, `$${formatMoney(totalValue)}` )
      totals.splice(injectCollectionsAt, 0, ...values)
    }
    setTotals(totals)
  }, [
    totalItems,
    totalSales,
    totalValue,
    selectedCollections,
    collectionTotals,
  ])

  const handleSort = useCallback(
    (index, direction) => {
      // setCurrentlySortedColumn(baseColumnCount + selectedCollections.length)
      setSortedRows(sortCurrency(constructedRows, index, direction))
    },
    [constructedRows /*, selectedCollections*/]
  )
  useEffect(() => {
    const visible = sortedRows.slice(
      pagerPage * tableDisplayCap,
      pagerPage * tableDisplayCap + tableDisplayCap
    )
    setVisibleRows(visible)
  }, [sortedRows, pagerPage])

  const incrementPager = useCallback(() => setPagerPage(pagerPage + 1), [
    pagerPage,
  ])
  const decrementPager = useCallback(() => setPagerPage(pagerPage - 1), [
    pagerPage,
  ])

  // const rows = sortedRows ? sortedRows : initiallySortedRows
  const pager = (
    <Pagination
      hasPrevious={pagerPage > 0}
      onPrevious={decrementPager}
      hasNext={(pagerPage + 1) * tableDisplayCap < allRowCount}
      label={`Showing ${visibleRows.length} of ${allRowCount} results`}
      onNext={incrementPager}
    />
  )

  return (
    <Card>
      <DataTable
        columnContentTypes={columnTypes}
        headings={headings}
        rows={visibleRows}
        totals={totals}
        sortable={[
          false,
          true,
          false,
          true,
          true,
          ...selectedCollections.map((_) => true),
        ]}
        defaultSortDirection="descending"
        initialSortColumnIndex={currentlySortedColumn}
        onSort={handleSort}
        // footerContent={`Showing ${rows.length} of ${allRowCount} results`}
        footerContent={pager}
      />
    </Card>
  )
}
