import React, { useCallback, useEffect, useState } from "react"
import {
  FormLayout,
  Popover,
  DatePicker,
  TextField,
  Select,
  Stack,
  TextStyle,
} from "@shopify/polaris"
import { createDateRange, RangeType } from "../../helpers/dates"

const zeroPad = (num: string | number, places: number) =>
  String(num).padStart(places, "0")

const formatDateString = (date: Date) => {
  const year = date.getFullYear()
  const month = zeroPad(date.getMonth() + 1, 2)
  const day = zeroPad(date.getDate(), 2)
  return `${year}-${month}-${day}`
}

export const DateSelector: React.FC<{
  onUpdate?: (update: { start: Date; end: Date }) => void
  customStartDate: Date
  customEndDate: Date
  defaultRange: RangeType | "custom"
}> = ({
  onUpdate,
  customStartDate = new Date(new Date().setMonth(new Date().getMonth() - 1)),
  customEndDate = new Date(),
  defaultRange = "thisMonth",
}) => {
  // 1 day ago new Date(new Date().setDate((new Date()).getDate() - 1));
  // 1 month ago new Date(new Date().setMonth((new Date()).getMonth() - 1));
  const rangeOptions: { label: string; value: RangeType | "custom" }[] = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "This week", value: "thisWeek" },
    { label: "Last week", value: "lastWeek" },
    { label: "This month", value: "thisMonth" },
    { label: "Last month", value: "lastMonth" },
    { label: "Two months ago", value: "twoMonthsAgo" },
    { label: "This year", value: "thisYear" },
    { label: "Last year", value: "lastYear" },
    { label: "Custom", value: "custom" },
  ]

  const [selectedRange, setSelectedRange] = useState(defaultRange)

  const [startDatePopoverActive, setStartDatePopoverActive] = useState(false)
  const [endDatePopoverActive, setEndDatePopoverActive] = useState(false)
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: customStartDate.getMonth(),
    startYear: customStartDate.getFullYear(),
  })
  const [{ endMonth, endYear }, setEndDate] = useState({
    endMonth: customEndDate.getMonth(),
    endYear: customStartDate.getFullYear(),
  })
  const [selectedStartDates, setSelectedStartDates] = useState({
    start: customStartDate,
    end: customStartDate,
  })
  const [selectedEndDates, setSelectedEndDates] = useState({
    start: customEndDate,
    end: customEndDate,
  })
  const [startDateText, setStartDateText] = useState(
    formatDateString(customStartDate)
  )
  const [endDateText, setEndDateText] = useState(
    formatDateString(customEndDate)
  )

  const toggleStartDatePopoverActive = useCallback(
    () => setStartDatePopoverActive((popoverActive) => !popoverActive),
    []
  )
  const toggleEndDatePopoverActive = useCallback(
    () => setEndDatePopoverActive((popoverActive) => !popoverActive),
    []
  )
  const showStartDatePopover = useCallback(
    () => setStartDatePopoverActive(true),
    []
  )
  const showEndDatePopover = useCallback(
    () => setEndDatePopoverActive(true),
    []
  )
  const handleStartMonthChange = useCallback(
    (startMonth, startYear) => setStartDate({ startMonth, startYear }),
    []
  )
  const handleEndMonthChange = useCallback(
    (endMonth, endYear) => setEndDate({ endMonth, endYear }),
    []
  )
  const updateStartDate = useCallback(({ start, end }) => {
    if (typeof start.getMonth === "function") {
      setStartDateText(formatDateString(start))
      setStartDate({
        startMonth: start.getMonth(),
        startYear: start.getFullYear(),
      })
    }
    setStartDatePopoverActive(false)
    setSelectedStartDates({ start, end })
  }, [])
  const updateEndDate = useCallback(({ start, end }) => {
    if (typeof start.getMonth === "function") {
      setEndDateText(formatDateString(start))
      setEndDate({
        endMonth: start.getMonth(),
        endYear: start.getFullYear(),
      })
    }
    setEndDatePopoverActive(false)
    setSelectedEndDates({ start, end })
  }, [])

  const updatePickerDateValues = useCallback(
    ({ start, end }: { start: Date; end: Date }) => {
      setStartDate({
        startMonth: start.getMonth(),
        startYear: start.getFullYear(),
      })
      setEndDate({
        endMonth: end.getMonth(),
        endYear: end.getFullYear(),
      })
    },
    []
  )

  const handleRangeSelectChange = useCallback((value) => {
    if (value !== "custom") {
      const { start, end } = createDateRange(value)
      updateStartDate({ start, end: start })
      updateEndDate({ start: end, end })
    }
    setSelectedRange(value)
  }, [])

  useEffect(() => {
    if (typeof onUpdate === "function") {
      onUpdate({
        start: selectedStartDates.start,
        end: selectedEndDates.start,
      })
    }
  }, [selectedStartDates, selectedEndDates])

  useEffect(() => {
    if (defaultRange === "custom") {
      // && customStartDate instanceof Date && customEndDate instanceof Date) {
    } else {
      const { start, end } = createDateRange(defaultRange)
      updateStartDate({ start, end: start })
      updateEndDate({ start: end, end })
    }
  }, [defaultRange])

  const startDateActivator = (
    <TextField
      label="Start date"
      onFocus={showStartDatePopover}
      value={startDateText}
      onChange={() => {}}
    />
  )

  const endDateActivator = (
    <TextField
      label="End date"
      onFocus={showEndDatePopover}
      value={endDateText}
      onChange={() => {}}
    />
  )

  return (
    <>
      <FormLayout>
        <>
          <Select
            label="Date range"
            options={rangeOptions}
            labelHidden
            onChange={handleRangeSelectChange}
            value={selectedRange}
          />
          {selectedRange !== "custom" && (
            <Stack>
              <Stack.Item fill>
                <TextStyle variation="subdued">{startDateText}</TextStyle>
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="subdued">{endDateText}</TextStyle>
              </Stack.Item>
            </Stack>
          )}
        </>
        {selectedRange === "custom" && (
          <FormLayout.Group>
            <Popover
              active={startDatePopoverActive}
              activator={startDateActivator}
              onClose={toggleStartDatePopoverActive}
              ariaHaspopup={false}
              sectioned
            >
              <DatePicker
                month={startMonth}
                year={startYear}
                onChange={updateStartDate}
                onMonthChange={handleStartMonthChange}
                selected={selectedStartDates}
              />
            </Popover>
            <Popover
              active={endDatePopoverActive}
              activator={endDateActivator}
              onClose={toggleEndDatePopoverActive}
              ariaHaspopup={false}
              sectioned
            >
              <DatePicker
                month={endMonth}
                year={endYear}
                onChange={updateEndDate}
                onMonthChange={handleEndMonthChange}
                selected={selectedEndDates}
              />
            </Popover>
          </FormLayout.Group>
        )}
      </FormLayout>
    </>
  )
}
