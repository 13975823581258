import React from "react"
import {
  Layout,
  Card,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextContainer,
} from "@shopify/polaris"
import { Loading } from "@shopify/app-bridge-react"

export const SkeletonApp: React.FC = () => {
  return (
    <SkeletonPage title={null} primaryAction={false} fullWidth>
      <Layout>
        <Layout.Section>
          {/* <Loading /> */}
          <Card sectioned>
            {/* <div style={{ marginBottom: "2rem" }}>
              <Stack distribution="fillEvenly">
                <SkeletonDisplayText size="large" />
                <SkeletonDisplayText size="large" />
                <SkeletonDisplayText size="large" />
              </Stack>
            </div> */}
              <TextContainer>

                <SkeletonDisplayText size="large" />
            <SkeletonBodyText lines={3} />
              </TextContainer>
          </Card>
          <Card>
            <Card.Section>
              {/* <div style={{ marginBottom: "2rem" }}>
                <Stack distribution="fillEvenly">
                  <SkeletonDisplayText size="small" />
                  <SkeletonDisplayText size="small" />
                  <SkeletonDisplayText size="small" />
                  <SkeletonDisplayText size="small" />
                </Stack>
              </div> */}
              <SkeletonBodyText lines={2} />
            </Card.Section>
            <Card.Section subdued>
              <SkeletonBodyText lines={1} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
            <Card.Section subdued>
              <SkeletonBodyText lines={4} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
