export type RangeType = "today" | 'yesterday' | 'thisWeek' |'lastWeek' | 'thisMonth' | 'lastMonth' | 'twoMonthsAgo' | 'thisYear' | 'lastYear'
export const createDateRange = (type: RangeType) => {
  // Calculate and set ranges: today, yesterday, this week, past week, this month, past month, this year
  let start = new Date()
  let end = new Date()
  const d = new Date()
  const y = d.getFullYear()
  const m = d.getMonth()
  switch (type) {
    case "today":
      start = new Date()
      end = new Date()
      break
    case "yesterday":
      d.setDate(d.getDate() - 1)
      start = new Date(d)
      end = new Date(d)
      break
    case "thisWeek":
      //   var d = new Date()
      const firstOfWeek = d.getDate() - d.getDay() + 1
      const lastOfWeek = firstOfWeek + 6
      start = new Date(d.setDate(firstOfWeek))
      end = new Date(d.setDate(lastOfWeek))
      break
    case "lastWeek":
      //   var d = new Date()
      const firstLastWeek = d.getDate() - 7 - d.getDay() + 1
      const lastLastWeek = firstLastWeek + 6
      end = new Date(d.setDate(lastLastWeek))
      start = new Date(d.setDate(firstLastWeek))
      break
    case "thisMonth":
      start = new Date(y, m, 1)
      end = new Date(y, m + 1, 0, 24)
      break
    case "lastMonth":
      start = new Date(y, m - 1, 1)
      end = new Date(y, m - 0, 0)
      break
    case "twoMonthsAgo":
        start = new Date(y, m - 2, 1)
        end = new Date(y, m - 1, 0)
        break
    case "thisYear":
      start = new Date(y, 0, 1)
      end = new Date(y, 11, 31)
      break
    case "lastYear":
        start = new Date(y - 1, 0, 1)
        end = new Date(y - 1, 11, 31)
        break;
    default:
      break
  }
  end.setHours(23)
  end.setMinutes(59)
  end.setSeconds(59)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  return {start, end};
}
